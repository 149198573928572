<template>
  <div class="region-add">
    <user-avatar :data="regionImage" @update="regionImage = $event"></user-avatar>
    <div :class="['region-add__multiselect', {errorSelect: errorText}]">
      <label :class="['typo__label', {errorLabel: errorText}]" for="multiSelect">{{ errorText || $t('region-name') }}</label>
      <Multiselect
          @search-change="getCityApi"
          id="multiSelect"
          v-model="city"
          :options="regions"
          placeholder=""
          :loading="isLoadingMultiSelect"
          :custom-label="getCityName"
          :close-on-select="true"
          :show-labels="false"
      >
        <template slot="noOptions" >
          {{ $t('enter-address') }}
        </template>
        <span slot="noResult">
          {{ $t('not-found') }}
        </span>
      </Multiselect>
    </div>
    <ui-button @click="addNewRegion" :disabled="!city?.name">{{ $t('create-region') }}</ui-button>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import UiButton from "@/components/ui/UiButton.vue";
import UserAvatar from "@/components/common/UserAvatar.vue";
import {mapActions} from "vuex";

export default {
  name: "RegionAdd",
  components: {UserAvatar, UiButton, Multiselect},
  data () {
    return {
      city: {},
      regions: [],
      isLoadingMultiSelect: false,
      regionImage: {},
      errorText: ''
    }
  },

  methods: {
    ...mapActions(['addRegion']),

    getCityApi(query) {
      this.errorText = ''
      this.regions = []
      this.isLoadingMultiSelect = true
      axios.get(`https://geocode-maps.yandex.ru/1.x?apikey=bedfc36e-e123-4c97-951b-4de2553423b2&format=json&lang=ru_RU&country_code=ru_RU&results=50&locality=kind&geocode=${query}`)
          .then((res) => {
            res.data.response.GeoObjectCollection.featureMember.forEach((val, idx) => {
              const { Address, kind } = val.GeoObject.metaDataProperty.GeocoderMetaData
              if(Address.country_code === 'RU' && kind === 'province') {
                const obj = {
                  id: idx + 1,
                  name: val.GeoObject.name
                }
                this.regions.push(obj)
              }
            })
            this.isLoadingMultiSelect = false
          })
          .catch(() => {
            this.isLoadingMultiSelect = false
          })
    },

    getCityName({ name }) {
      return name
    },

    addNewRegion() {
      let payload = {
        name: this.city.name,
        image: this.regionImage.filename
      }

      this.addRegion(payload).then(() => {
        this.$router.push('/admin/region')
      }).catch((err) => {
        if(err.response.data.constraint === 'regions_name_unique') {
          this.errorText = this.$t('this-region-already-exists')
        }
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.region-add {
  max-width: 380px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 15px;
  height: 250px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: 151px;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  &__multiselect {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    label {
      margin-left: 30px;
      font-size: 12px;
      color: #343432;
    }
  }
}
::v-deep .multiselect__tags {
  border-radius: 25px !important;
  border: 1px solid #9A9A9A !important;
  max-height: 198px !important;
}
::v-deep .multiselect__option {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__content-wrapper {
  border-radius: 10px;
}
::v-deep .multiselect__single {
  font-size: 12px;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__option--selected.multiselect__option--highlight {
  background: none !important;
}
::v-deep .multiselect__option--selected {
  color: #E21F1F !important;
}
::v-deep .multiselect__option--highlight {
  background: none !important;
  color: #E21F1F !important;
}
::v-deep .multiselect__input {
  font-size: 12px !important;
  font-family: "Gotham Pro Regular";
}
::v-deep .multiselect__spinner {
  border-radius: 28px;
}
.errorLabel {
  color: #E21F1F !important;
  animation: 0.25s shake
}
.errorSelect {
  ::v-deep .multiselect__tags {
    border-radius: 25px !important;
    border: 1px solid #E21F1F !important;
    max-height: 198px !important;
  }
}
</style>
